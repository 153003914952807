<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="type === 0">
        <el-breadcrumb-item>商品发货管理</el-breadcrumb-item>
        <el-breadcrumb-item>发货列表</el-breadcrumb-item>
        <el-breadcrumb-item>新增发货</el-breadcrumb-item>
      </el-breadcrumb>

      <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
        <el-breadcrumb-item>商品收货管理</el-breadcrumb-item>
        <el-breadcrumb-item>收货列表</el-breadcrumb-item>
        <el-breadcrumb-item>新增收货</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt20">
      <div>
        <el-form :model="formData" ref="formName" :rules="rules">
          <el-form-item
            :label="type ? '发货企业' : '收货企业'"
            prop="companyName"
          >
            <!-- <div class="company-box" @click="openSupplyDialog">
              {{ formData.companyName }}
            </div> -->
            <div class="company-box">
              <div class="company-modal" @click="openSupplyDialog"></div>
              <el-input
                v-model="formData.companyName"
                :placeholder="`选择${type ? '发货' : '收货'}企业`"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="`${type ? '收货' : '发货'}货品`">
            <div class="company-box">
              <el-button
                type="primary"
                @click="openGoodsDialog"
                class="general_bgc general_border cfff"
                >商品选择</el-button
              >
            </div>
          </el-form-item>
          <el-form-item>
            <el-table
              v-if="tableData.length > 0"
              border
              :data="tableData"
              style="width: 100%"
              id="out-table"
              empty-text="尚未选择商品"
              ref="goodsTable"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="p20">
                    <div
                      v-if="!props.row.batchList.length"
                      class="empty-box df"
                    >
                      请选择商品批次
                    </div>
                    <div
                      v-for="(item, index) in props.row.batchList"
                      :key="item.batch_id"
                    >
                      <el-form
                        inline
                        :model="item"
                        class="demo-form-inline demo-table-expand pb20"
                        v-if="item.unit"
                        :rules="rulesWithUnit"
                        :ref="`form${props.row.id}i${index}`"
                      >
                        <el-form-item label="批号">
                          {{ item.batch_number }}
                        </el-form-item>
                        <el-form-item
                          label="数量"
                          prop="num"
                          style="width: 300px"
                          class="warehouse"
                        >
                          <div class="df">
                            <div style="flex: 1">
                              <el-input v-model.number="item.num"></el-input>
                            </div>
                            <div style="flex: 0 0 40%">
                              <el-select v-model="item.unit">
                                <el-option
                                  v-for="val in item.unitList"
                                  :key="val.value"
                                  :label="val.label"
                                  :value="val.value"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item label="单价(元)" prop="price">
                          <el-input v-model="item.price"></el-input>
                        </el-form-item>
                        <el-form-item
                          label="库房"
                          prop="price"
                          required
                          class="warehouse"
                        >
                          <div style="width: 80%">
                            <!-- <el-input
                              v-model="item.warehouse_name"
                              :disabled="type != 1"
                            ></el-input> -->
                            <el-select
                              v-model="item.warehouseName"
                              :placeholder="type ? '请选择' : '未设置库房'"
                              :disabled="type != 1"
                              @change="
                                (value) => selectChange(value, index, props.row)
                              "
                            >
                              <el-option
                                v-for="item in warehouseOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="text"
                            class="disib red"
                            size="mini"
                            @click="removeBatch(props.row, index)"
                            >移除批次</el-button
                          >
                        </el-form-item>
                      </el-form>
                      <el-form
                        inline
                        :model="item"
                        class="demo-form-inline demo-table-expand pb20"
                        :rules="rulesWithoutUnit"
                        v-else
                        :ref="`form${props.row.id}i${index}`"
                      >
                        <el-form-item label="批号">
                          {{ item.batch_number }}
                        </el-form-item>
                        <el-form-item label="货品件数" prop="goodsNumber">
                          <el-input
                            v-model.number="item.goodsNumber"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label-width="110px"
                          label="每件单品数"
                          prop="goodsUnit"
                        >
                          <el-input v-model="item.goodsUnit"></el-input>
                        </el-form-item>
                        <el-form-item label-width="110px" label="零散单品数">
                          <el-input v-model.number="item.goodsItem"></el-input>
                        </el-form-item>
                        <el-form-item
                          label-width="110px"
                          label="单品重量(kg)"
                          prop="goodsWeightUnit"
                        >
                          <el-input v-model="item.goodsWeightUnit"></el-input>
                        </el-form-item>
                        <el-form-item
                          label-width="110px"
                          label="单价(元/单品)"
                          prop="goodsUnitPrice"
                        >
                          <el-input v-model="item.goodsUnitPrice"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="text"
                            class="disib red"
                            size="mini"
                            @click="removeBatch(props.row, index)"
                            >移除批次</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                align="center"
                width="60"
                label="序号"
              ></el-table-column>
              <el-table-column
                prop="gtin"
                min-width="120"
                :show-overflow-tooltip="true"
                label="商品编码"
                align="center"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="商品名"
                min-width="120"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="manufacturer"
                min-width="120"
                :show-overflow-tooltip="true"
                label="生产商"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="stock"
                min-width="120"
                :show-overflow-tooltip="true"
                label="库存(kg)"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="num"
                min-width="120"
                :show-overflow-tooltip="true"
                label="数量"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="type"
                min-width="120"
                :show-overflow-tooltip="true"
                label="类型"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="180"
                fixed="right"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="disib"
                    size="mini"
                    @click="
                      type
                        ? handleBatch(scope.row)
                        : handlebatchStock(scope.row)
                    "
                    >批次选择</el-button
                  >
                  <el-button
                    type="text"
                    class="disib red"
                    size="mini"
                    @click="removeGoods(scope.row)"
                    >移除商品</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <div class="company-box">
              <el-button type="success" @click="onSubmit">{{
                type ? "确认收货" : "确认发货"
              }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <el-dialog title="商品选择" :visible.sync="dialogGoodsVisible">
        <div class="pt20" style="text-align: left">
          <el-form :inline="true" :model="goodsPage" class="demo-form-inline">
            <el-form-item>
              <el-input
                placeholder="商品名"
                v-model="goodsPage.name"
                clearable
                @clear="searchGoods"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="商品编码"
                v-model="goodsPage.gtin"
                clearable
                @clear="searchGoods"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="goodsPage.goods_type" placeholder="商品类型">
                <el-option value="0" label="全部"></el-option>
                <el-option value="1" label="国产食品"></el-option>
                <el-option value="2" label="进口食品"></el-option>
                <el-option value="3" label="农产品"></el-option>
                <el-option value="4" label="其他产品"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchGoods" size="medium"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-row class="box search mt20">
          <el-form :inline="true" :model="goodsPage" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="商品名"
                  v-model="goodsPage.name"
                  clearable
                  class="input-with-select"
                  @clear="searchGoods"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="商品编码"
                  v-model="goodsPage.gtin"
                  clearable
                  class="input-with-select"
                  @clear="searchGoods"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-select
                  v-model="goodsPage.goods_type"
                  placeholder="商品类型"
                >
                  <el-option value="0" label="全部"></el-option>
                  <el-option value="1" label="国产食品"></el-option>
                  <el-option value="2" label="进口食品"></el-option>
                  <el-option value="3" label="农产品"></el-option>
                  <el-option value="4" label="其他产品"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="tl mr10">
              <el-form-item size="medium">
                <div class="df">
                  <el-button
                    type="primary"
                    @click="searchGoods"
                    class="general_bgc general_border cfff"
                    >查询</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row> -->
        <el-table :data="goodsList" v-loading="goodsLoading">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="gtin"
            min-width="120"
            :show-overflow-tooltip="true"
            label="商品编码"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="商品名"
            min-width="120"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="manufacturer"
            min-width="120"
            :show-overflow-tooltip="true"
            label="生产商"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="stock"
            min-width="120"
            :show-overflow-tooltip="true"
            label="库存(kg)"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="num"
            min-width="120"
            :show-overflow-tooltip="true"
            label="数量"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="type"
            min-width="120"
            :show-overflow-tooltip="true"
            label="类型"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="handleGoods(scope.row)"
                >选择商品</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="goodsTotal"
            :current-page="goodsCurrentPage"
            @current-change="goodsCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-dialog title="批次选择" :visible.sync="dialogBatchVisible">
        <el-form
          :inline="true"
          :model="batchPage"
          class="demo-form-inline df"
          style="justify-content: flex-start"
        >
          <el-form-item size="medium" style="flex: 0 0 300px; width: 0">
            <el-input
              placeholder="批次号"
              v-model="batchPage.batch_number"
              clearable
              @clear="batchSearch"
            >
            </el-input>
          </el-form-item>
          <el-button
            type="primary"
            @click="batchSearch"
            style="height: 36px; margin-right: 10px"
            >&emsp;查询&emsp;</el-button
          >

          <el-popover
            placement="top"
            width="500"
            trigger="click"
            v-model="createBatchVisible"
          >
            <div class="p15">
              <el-form
                :model="batchForm"
                :rules="batchRules"
                ref="batchForm"
                label-width="100px"
                class="demo-dForm"
              >
                <el-form-item :label="'批次号'" prop="batchNumber" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.batchNumber"></el-input>
                  </div>
                </el-form-item>

                <el-form-item label="库存/kg" prop="stock" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.stock"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="单价/元" prop="price" required>
                  <div style="width: 80%">
                    <el-input v-model="batchForm.price"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="类型" required>
                  <div class="fl">
                    <el-select
                      v-model="batchForm.selfProduced"
                      placeholder="请选择类型"
                    >
                      <el-option label="外购" value="waigou"></el-option>
                      <el-option label="自产" value="zichan"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createBatchVisible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createBatch"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="success" style="height: 36px"
              >创建批号</el-button
            >
          </el-popover>
          <el-form-item size="medium">
            <!-- <el-button type="success" @click="batchSearch"
                >创建批号</el-button
              > -->
          </el-form-item>
        </el-form>
        <el-table
          :data="batchList"
          border
          fit
          height="579"
          v-loading="batchLoading"
        >
          <el-table-column
            :property="type ? 'goods_name' : 'gtinInfo'"
            label="商品名"
            min-width="200"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="!type">
                {{ JSON.parse(scope.row.gtinInfo).goodsName }}
              </span>
              <span v-else>{{ scope.row.goods_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :property="type ? 'batch_number' : 'batchNumber'"
            label="批号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            v-if="!type"
            property="warehouseName"
            label="库房"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.warehouseName ? scope.row.warehouseName : "" }}
            </template>
          </el-table-column>
          <el-table-column
            property="stock"
            label="库存(kg)"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="handleBatchSure(scope.row)"
                >批次选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="batchTotal"
            :current-page="batchCurrentPage"
            @current-change="batchCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <el-dialog
        :title="`${type ? '发货' : '收货'}企业选择`"
        :visible.sync="dialogSupplyVisible"
      >
        <div class="pt20" style="text-align: left">
          <el-form :inline="true" :model="supplyPage" class="demo-form-inline">
            <el-form-item>
              <el-input
                placeholder="供销企业名称"
                v-model="supplyPage.supply_name"
                clearable
                @clear="supplySearch"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="联系人"
                v-model="supplyPage.concat"
                clearable
                @clear="supplySearch"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" @click="searchGoods" size="medium"
                >查询</el-button
              > -->
              <el-button type="primary" @click="supplySearch" size="medium"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-row class="box search mt20">
          <el-form :inline="true" :model="supplyPage" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="供销企业名称"
                  v-model="supplyPage.supply_name"
                  clearable
                  class="input-with-select"
                  @clear="supplySearch"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="联系人"
                  v-model="supplyPage.concat"
                  clearable
                  class="input-with-select"
                  @clear="supplySearch"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="supplySearch"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
        </el-row> -->

        <el-table
          :data="supplyList"
          border
          fit
          height="579"
          v-loading="goodsLoading"
        >
          <el-table-column
            fixed
            align="center"
            width="100"
            ref="multipleTable"
            type="index"
            label="序号"
          >
          </el-table-column>

          <el-table-column
            prop="supply_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="供销单位名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="contact"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="contact_info"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人电话"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="credit_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="营业执照"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.credit_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="record_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="备案证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.record_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="license_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="许可证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.license_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="credit_code"
            min-width="160"
            :show-overflow-tooltip="true"
            label="统一社会信用代码"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="handleBSupplySure(scope.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="supplyTotal"
            :current-page="supplyCurrentPage"
            @current-change="supplyCurrentChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import { local, session } from "@/util/util";
import requestApi from "@/util/extra-api";
import axios from "@/util/api";

export default {
  data() {
    return {
      type: 0,
      formData: {
        companyName: "",
        companyId: "",
        creditCode: "",
      },
      tableData: [],
      goodsList: [],
      batchList: [],
      supplyList: [],
      createBatchVisible: false,
      dialogGoodsVisible: false,
      dialogBatchVisible: false,
      dialogSupplyVisible: false,
      goodsPage: {
        pager_offset: "0",
        pager_openset: "10",
        name: "",
        gtin: "",
        goods_type: "",
      },
      batchPage: {
        pager_offset: "0",
        pager_openset: "10",
        goods_id: "",
        company_id: "",
        gtin: "",
        batch_number: "",
      },
      supplyPage: {
        supply_name: "", //供销单位的名称
        concat: "", //联系人
        pager_offset: "0",
        pager_openset: "10",
        company_id: null,
      },
      goodsTotal: 0,
      batchTotal: 0,
      supplyTotal: 0,
      goodsCurrentPage: 1,
      batchCurrentPage: 1,
      supplyCurrentPage: 1,
      chooseGoodsId: null,
      goodsLoading: false,
      batchLoading: false,
      rulesWithUnit: {
        num: [{ required: true, message: "请输入货品数量", trigger: "blur" }],
      },
      rulesWithoutUnit: {
        goodsNumber: [
          { required: true, message: "请输入货品件数", trigger: "blur" },
          {
            type: "number",
            required: true,
            message: "货品件数需为整数",
            trigger: "change",
          },
        ],
        goodsUnit: [
          { required: true, message: "请输入每件单品数", trigger: "blur" },
        ],
        goodsWeightUnit: [
          { required: true, message: "请输入单品重量(kg/件)", trigger: "blur" },
        ],
        goodsUnitPrice: [
          { required: true, message: "请输入单价", trigger: "blur" },
        ],
      },
      rules: {
        companyName: [
          {
            required: true,
            message: `请选择${this.type ? "发货" : "收货"}公司`,
            trigger: "blur",
          },
        ],
      },
      batchForm: {
        batchNumber: null,
        stock: null,
        price: null,
        selfProduced: "外购",
        goodsId: null,
        companyId: null,
      },
      batchRules: {
        batchNumber: [
          { required: true, message: "请填写批号", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请填写库存", trigger: "blur" }],
        price: [{ required: true, message: "请填写价格", trigger: "blur" }],
      },
      warehouseOptions: [],
    };
  },
  mounted() {
    this.type = Number(this.$route.query.type);
    this.getWarehouseOptions();
    this.supplyPage.company_id = String(local.get("company_id"));
    this.batchPage.company_id = String(local.get("company_id"));
  },
  methods: {
    selectChange(value, index, row) {
      console.log(value, index, row);
      this.$set(row.batchList[index], "warehouseId", value);
      this.$set(
        row.batchList[index],
        "warehouseName",
        this.warehouseOptions.filter((v) => v.value == value)[0].label
      );
    },
    getWarehouseOptions() {
      axios
        .get("/pc/company-warehouse/all", {
          company_id: String(local.get("company_id")),
        })
        .then((res) => {
          this.warehouseOptions = res.data.warehouse_list.map((v) => {
            return {
              label: v.warehouse_name,
              value: v.warehouse_id,
            };
          });
        });
    },
    createBatch() {
      const { goods_id, company_id } = this.batchPage;
      this.batchForm.goodsId = goods_id;
      this.batchForm.companyId = company_id;
      this.batchForm.selfProduced = this.selfProduced === "自产";
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          requestApi
            .post("/v1/pc/stock/createBatchStock", this.batchForm)
            .then(async (v) => {
              this.batchCurrentPage = 1;
              this.batchPage.pager_offset = "0";
              let res = await this.initBatch(this.batchPage);
              this.batchList = res.data.batch_list;
              this.batchTotal = res.data.pager_count;
              this.createBatchVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    removeBatch(row, index) {
      // console.log(row, index);
      row.batchList = row.batchList.filter((v, i) => i !== index);
    },
    removeGoods(row) {
      this.tableData = this.tableData.filter((v) => v.id !== row.id);
    },
    handlePictureCardPreview(url) {
      if (url) {
        this.dialogImageUrl = url;
        this.showViewer = true;
      } else {
        this.$message.warning("没有上传证件！");
      }
    },
    openSupplyDialog() {
      this.dialogSupplyVisible = true;
      this.supplyInit(this.supplyPage);
    },
    supplySearch() {
      this.supplyPage.pager_offset = "0";
      this.supplyInit(this.supplyPage);
    },
    supplyInit(page) {
      this.goodsLoading = true;
      axios.get("/pc/company-supply/list", page).then((v) => {
        this.supplyList = v.data.company_list;
        this.supplyTotal = v.data.pager_count;
        this.goodsLoading = false;
      });
    },
    supplyCurrentChange(page) {
      this.supplyCurrentPage = page;
      this.supplyPage.pager_offset = String((page - 1) * 10);
      this.supplyInit(this.supplyPage);
    },
    handleBSupplySure(row) {
      this.formData.companyId = row.supply_id;
      this.formData.companyName = row.supply_name;
      this.formData.creditCode = row.credit_code;
      this.dialogSupplyVisible = false;
    },
    goodsCurrentChange(page) {
      this.goodsCurrentPage = page;
      this.goodsPage.pager_offset = String((page - 1) * 10);
      this.init(this.goodsPage);
    },
    batchCurrentChange(page) {
      this.batchCurrentPage = page;
      this.batchPage.pager_offset = String((page - 1) * 10);
      this.batchLoading = true;
      if (this.type) {
        axios.get("/pc/batch/list", this.batchPage).then((res) => {
          this.batchList = res.data.batch_list.map((m) => {
            let quantity = "";
            if (m.units)
              m.units.forEach((item) => {
                quantity += item.unit_num + item.unit_name;
              });
            return {
              ...m,
              quantity: quantity,
            };
          });
          this.batchTotal = res.data.pager_count;
          this.batchLoading = false;
        });
      } else {
        axios.get("/pc/stock/batch_stock", this.batchPage).then((res) => {
          console.log(res.data);
          this.batchList = res.data.batch_list.map((m) => {
            let quantity = "";
            if (m.units)
              m.units.forEach((item) => {
                quantity += item.unit_num + item.unit_name;
              });
            return {
              ...m,
              quantity: quantity,
            };
          });
          this.batchTotal = res.data.pager_count;
          this.batchLoading = false;
        });
      }
    },
    handleGoods(row) {
      if (!this.tableData.filter((v) => v.id === row.id).length) {
        this.tableData.push(row);
      } else {
        this.$message({
          message: "该商品已被选择",
          type: "warning",
        });
      }
      this.dialogGoodsVisible = false;
    },
    handlebatchStock(row) {
      this.batchLoading = true;
      this.chooseGoodsId = row.id;
      this.batchPage.batch_number = "";
      this.batchPage.goods_id = row.id + "";
      this.batchPage.gtin = row.gtin + "";
      this.batchPage.name = "";
      axios.get("/pc/stock/batch_stock", this.batchPage).then((res) => {
        console.log(res.data);
        this.batchList = res.data.batch_list.map((m) => {
          let quantity = "";
          if (m.units)
            m.units.forEach((item) => {
              quantity += item.unit_num + item.unit_name;
            });
          return {
            ...m,
            quantity: quantity,
          };
        });
        this.batchLoading = false;
        this.batchTotal = res.data.pager_count;
        this.dialogBatchVisible = true;
      });
    },
    async handleBatch(row) {
      this.batchLoading = true;
      this.chooseGoodsId = row.id;
      this.batchPage.batch_number = "";
      this.batchPage.goods_id = row.id + "";
      this.batchPage.gtin = row.gtin + "";
      this.batchPage.name = "";
      this.batchPage.company_id = local.get("company_id") + "";
      let res = await this.initBatch(this.batchPage);
      this.batchPage.name = row.name + "";
      this.batchList = res.data.batch_list.map((m) => {
        let quantity = "";
        if (m.units)
          m.units.forEach((item) => {
            quantity += item.unit_num + item.unit_name;
          });
        return {
          ...m,
          quantity: quantity,
        };
      });
      this.batchLoading = false;
      this.batchTotal = res.data.pager_count;
      this.dialogBatchVisible = true;
    },
    init(params) {
      this.goodsLoading = true;
      axios.get("/pc/goods/list", params).then((v) => {
        this.goodsList = v.data.goods_list.map((m) => {
          let num = "";
          if (m.unit !== "未设置单位!" && m.unit.length > 0) {
            m.unit.forEach((item, index) => {
              if (index !== m.unit.length - 1) {
                num += item.unit_num + item.unit_name + "+";
              } else {
                num += item.unit_num + item.unit_name;
              }
            });
            m.unit = m.unit.filter((val) => val.unit_name !== "kg");
          } else {
            num = "无单位";
          }
          return {
            ...m,
            num,
            batchList: [],
          };
        });
        this.goodsTotal = v.data.pager_count;
        this.goodsLoading = false;
      });
    },
    initBatch(params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/batch/list", params)
          .then((v) => {
            resolve(v);
          })
          .catch((err) => {
            reject(v);
          });
      });
    },
    openGoodsDialog() {
      this.goodsPage = Object.assign({}, this.goodsState);
      this.goodsPage.company_id = local.get("company_id") + "";
      this.goodsCurrentPage = this.goodsPage.pager_offset / 10 + 1;
      this.init(this.goodsPage);
      this.dialogGoodsVisible = true;
    },
    searchGoods() {
      this.goodsPage.pager_offset = "0";
      this.init(this.goodsPage);
    },
    handleBatchSure(row) {
      if (this.tableData.filter((v) => v.id === this.chooseGoodsId).length) {
        // if (
        //   !this.tableData
        //     .filter((v) => v.id === this.chooseGoodsId)[0]
        //     .batchList.filter((v) => v.batch_id === row.batch_id).length
        // ) {
        if (
          this.tableData.filter((v) => v.id === this.chooseGoodsId)[0].unit !==
            "未设置单位!" &&
          this.tableData.filter((v) => v.id === this.chooseGoodsId)[0].unit
            .length > 0
        ) {
          this.tableData
            .filter((v) => v.id === this.chooseGoodsId)[0]
            .batchList.push({
              batch_number: this.type ? row.batch_number : row.batchNumber,
              batch_id: row.batch_id,
              warehouseName: row.warehouseName,
              warehouseId: row.warehouseId,
              unit: this.tableData.filter((v) => v.id === this.chooseGoodsId)[0]
                .unit[0].unit_name,
              price: this.type
                ? this.tableData.filter((v) => v.id === this.chooseGoodsId)[0]
                    .unit[0].purchasing_price
                : this.tableData.filter((v) => v.id === this.chooseGoodsId)[0]
                    .unit[0].price,
              unitList: this.tableData
                .filter((v) => v.id === this.chooseGoodsId)[0]
                .unit.map((val) => {
                  return {
                    label: val.unit_name,
                    value: val.unit_name,
                  };
                }),
            });
        } else {
          this.tableData
            .filter((v) => v.id === this.chooseGoodsId)[0]
            .batchList.push({
              batch_number: row.batch_number,
              batch_id: row.batch_id,
              goodsNumber: "",
              goodsUnit: "",
              goodsItem: "",
              goodsWeightUnit: "",
              goodsUnitPrice: "",
            });
        }
        this.$refs.goodsTable.toggleRowExpansion(
          this.tableData.filter((v) => v.id === this.chooseGoodsId)[0],
          true
        );
        // } else {
        // this.$message({
        //   message: "该批次已被选择",
        //   type: "warning",
        // });

        // }
      }
      this.dialogBatchVisible = false;
    },
    async batchSearch() {
      this.batchPage.pager_offset = "0";
      if (this.type) {
        let res = await this.initBatch(this.batchPage);
        this.batchList = res.data.batch_list;
        this.batchTotal = res.data.pager_count;
      } else {
        axios.get("/pc/stock/batch_stock", this.batchPage).then((res) => {
          console.log(res.data);
          this.batchList = res.data.batch_list.map((m) => {
            let quantity = "";
            if (m.units)
              m.units.forEach((item) => {
                quantity += item.unit_num + item.unit_name;
              });
            return {
              ...m,
              quantity: quantity,
            };
          });
          this.batchTotal = res.data.pager_count;
          this.batchLoading = false;
        });
      }
    },
    verifyData(data) {
      if (data.unit) {
        if (!data.num || !data.price) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          !data.goodsUnit ||
          !data.goodsItem ||
          !data.goodsWeightUnit ||
          !data.goodsUnitPrice
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    onSubmit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          if (this.tableData.length) {
            let flag = true;
            // console.log(this.$refs);
            this.tableData.forEach((item, index) => {
              if (!item.batchList.length) {
                flag = false;
                this.$refs.goodsTable.toggleRowExpansion(item, true);
              } else {
                item.batchList.forEach((val, i) => {
                  if (!this.verifyData(val)) {
                    flag = false;
                    this.$refs.goodsTable.toggleRowExpansion(item, true);
                    let refName = `form${item.id}i${i}`;
                    // console.log(refName, String(refName), this.$refs);

                    this.$refs[refName][0].validate((valid) => {
                      if (!valid) {
                        return false;
                      }
                    });
                    this.$refs[refName][1].validate((valid) => {
                      if (!valid) {
                        return false;
                      }
                    });
                  }
                });
              }
            });

            if (flag) {
              let goodsData = [];
              this.tableData.forEach((v) => {
                let data = {
                  companyId: this.formData.companyId,
                  creditCode: this.formData.creditCode,
                  goodsId: v.id,
                };
                data.batchInfo = v.batchList.map((item) => {
                  if (item.unit) {
                    let batchInfo = {
                      batchNumber: item.batch_number,
                    };
                    batchInfo.units = v.unit
                      .filter((val) => val.unit_name === item.unit)
                      .map((val) => {
                        return {
                          num: item.num,
                          price: item.price,
                          unitId: val.unit_id,
                        };
                      });
                    batchInfo.goodsTotalPrice = Number(
                      (
                        Number(batchInfo.units[0].num) *
                        Number(batchInfo.units[0].price)
                      ).toFixed(2)
                    );
                    batchInfo.goodsWeight = Number(
                      (
                        Number(batchInfo.units[0].num) *
                        v.unit.filter((val) => val.unit_name === item.unit)[0]
                          .unit_weight
                      ).toFixed(4)
                    );
                    batchInfo.warehouseId = item.warehouseId;
                    batchInfo.warehouseName = item.warehouseName;
                    return batchInfo;
                  } else {
                    let goodsSum =
                      Number(item.goodsUnit) * Number(item.goodsNumber) +
                      (item.goodsItem ? Number(item.goodsItem) : 0);
                    let goodsTotalPrice = Number(
                      (Number(item.goodsWeightUnit) * goodsSum).toFixed(2)
                    );
                    let goodsWeight = Number(
                      (Number(item.goodsWeightUnit) * goodsSum).toFixed(4)
                    );
                    let batchInfo = {
                      batchNumber: item.batch_number,
                      goodsUnit: item.goodsUnit,
                      goodsNumber: item.goodsNumber,
                      goodsUnitPrice: item.goodsUnitPrice,
                      goodsSum,
                      goodsTotalPrice,
                      goodsWeight,
                      goodsWeightUnit: item.goodsWeightUnit,
                    };
                    if (item.goodsItem) {
                      batchInfo.goodsItem = item.goodsItem;
                    }
                    batchInfo.warehouseId = item.warehouseId;
                    batchInfo.warehouseName = item.warehouseName;

                    return batchInfo;
                  }
                });
                goodsData.push(data);
              });
              console.log(goodsData, 123);
              requestApi
                .post1(
                  `/v1/pc/receipt-ship/batchHandle?companyId=${String(
                    local.get("company_id")
                  )}&type=${this.type}`,
                  { list: goodsData }
                )
                .then((res) => {
                  if (res.code === 200) {
                    this.$router.go(-1);
                  }
                });
            } else {
              this.$message({
                message: "请完整填写相关信息",
                type: "warning",
              });
            }
          } else {
            this.$message({
              message: "尚未选择商品",
              type: "warning",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}

.demo-table-expand {
  font-size: 0;

  /deep/ .el-form-item__label {
    width: 90px;
    color: #99a9bf;
  }

  /deep/ .el-form-item__content {
    width: 55%;
  }

  /deep/ .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 13%;
  }
}
.company-box {
  width: 200px;
  // background-color: #fff;
  height: 40px;
  margin-left: 80px;
  position: relative;
  text-align: left;
}
.company-modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.empty-box {
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #99a9bf;
}
.warehouse {
  width: 20% !important;
}
</style>